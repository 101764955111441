<template>
    <section data-test-id="Profile setup form">
        <h1 class="t-title text-center mb-8">{{ $t('account.personalize') }}</h1>

        <div class="mb-9">
            <label class="grey--text body-1">
                <b style="text-transform: uppercase;">{{ $t('settings.profile.profileImage') }} </b>
            </label>

            <div class="profile-photo my-3">
                <user-avatar v-if="currentUser" image-only :user="currentUser" size="extra-large">
                    <template #upload-image>
                        <base-photo-input :input-label="$t('settings.profile.profileImage')" :input-aria-hidden="true" @loaded="updateProfilePhoto">
                            <base-button tag="span" color="primary" fab rounded small :loading="avatarLoading">
                                <span class="d-sr-only">({{ $t('settings.profile.setProfileImage') }})</span>
                                <v-icon>add_a_photo</v-icon>
                            </base-button>
                        </base-photo-input>
                    </template>
                </user-avatar>

            </div>
            <span class="help-text">{{ $t('settings.profile.profileImageHint') }}</span>
        </div>

        <div class="mb-10">
            <label class="grey--text body-1">
                <b style="text-transform: uppercase;">{{ $t('settings.profile.quickBio') }}</b> <small>{{ $t('settings.profile.optional') }}</small>
            </label>
            <validation-provider v-slot="{ /*errors*/ }" name="bio" rules="max:120">
                <base-text-area
                    v-model="userData.description"
                    :filled="false"
                    counter
                    :hint="$t('settings.profile.quickBioHint')"
                    label=""
                    maxlength="120"
                    multi-line
                    persistent-hint
                    :placeholder="$t('settings.profile.quickBioNote')"
                    rows="2"
                    type="text"
                    data-test-id="Bio field"
                />
            </validation-provider>
        </div>

        <action-footer class="text-center">
            <div>
                <base-button color="primary" type="submit" prepend-icon="arrow_forward" :loading="loading" data-test-id="Submit button" @click="saveUserData">{{ $t('actions.done') }}</base-button>
            </div>
            <div class="mt-4">
                <a v-if="nextRoute.includes('://')" :href="nextRoute">{{ $t('actions.skip') }}</a>
                <router-link v-else :to="nextRoute">{{ $t('actions.skip') }}</router-link>
            </div>
        </action-footer>
    </section>
</template>
<script lang="ts">
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import UserAvatar from '@/components/UserAvatar.vue';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import ActionFooter from '@/components/ActionFooter.vue';

export default Vue.extend({
    name: 'SetupProfile',
    components: {
        UserAvatar,
        ValidationProvider,
        ActionFooter,
    },
    props: {
        nextRoute: { type: String, default: '' },
    },
    data() {
        return {
            userData: {
                description: '',
            } as CurrentUser,
            avatarLoading: false,
            loading: false
        };
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler(val: CurrentUser) {
                if (val) {
                    this.userData.description = val.description;
                }
            },
        },
    },
    methods: {
        async updateProfilePhoto(photo: { webLink: string, file: any }) {
            if (this.currentUser) {
                this.avatarLoading = true;
                await this.$store.dispatch('updateAvatar', {
                    file: photo.file,
                    userId: this.currentUser.id,
                });
                this.avatarLoading = false;
            }
        },
        async saveUserData() {
            this.loading = true;
            await this.$store.dispatch('updateUserDetails', {
                description: this.userData.description,
                successMessage: false,
            });
            this.loading = false;

            if (this.nextRoute.includes('://')) {
                location.assign(this.nextRoute);
            } else {
                this.$router.push(this.nextRoute);
            }
        },
    },

});
</script>
<style lang="postcss" scoped>
:deep(.v-messages__wrapper) {
    font-style: italic;
}
:deep(.v-textarea) {
    .v-input__slot {
        padding: 0px;
        border: none !important;
    }
}
.help-text {
    color: rgba(0, 0, 0, 0.6);
    line-height: 12px;
    font-size: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-style: italic;
}

.profile-photo {
    position: relative;
}

:deep(.photo-input) {
    position: absolute;
    top: 0px;
    left: 90px;
    top: 90px;
}
.profile {

}
</style>
