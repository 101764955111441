<template>
    <component :is="$vuetify.breakpoint.xsOnly ? 'div' : 'v-container'">
        <h1 class="t-title text-center py-10">{{ $t('settings.sectionHeading') }}</h1>
        <v-layout class="account-container">
            <v-flex v-if="!$vuetify.breakpoint.xsOnly" shrink>
                <v-tabs vertical>
                    <!-- <v-tab id="account-page-tab-for_account.comments" :to="{ name: 'account.comments' }">{{ $t('nav.comments') }}</v-tab> -->
                    <v-tab id="account-page-tab-for_account.sensors" replace :to="{ name: 'account.sensors' }">{{ $t('nav.sensors') }}</v-tab>
                    <v-tab id="account-page-tab-for_account.notifications" replace :to="{ name: 'account.notifications' }">{{ $t('nav.notifications') }}</v-tab>
                    <v-tab id="account-page-tab-for_account.profile" replace :to="{ name: 'account.profile' }">{{ $t('nav.profile') }}</v-tab>
                    <v-tab id="account-page-tab-for_account.integrations" replace :to="{ name: 'account.integrations' }">{{ $t('nav.integrations') }}</v-tab>
                    <v-tab id="account-page-tab-for_account.login-details" replace :to="{ name: 'account.login-details' }">{{ $t('nav.loginDetails') }}</v-tab>
                    <v-tab id="account-page-tab-for_account.delete" replace :to="{ name: 'account.delete' }">{{ $t('nav.deleteAccount') }}</v-tab>
                </v-tabs>
            </v-flex>
            <v-flex class="tabs-content large-container">
                <router-view role="tabpanel" :aria-labelledby="`account-page-tab-for_${$route.name}`" />
            </v-flex>
        </v-layout>
    </component>
</template>
<script lang="ts">
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    name: 'Profile',
    metaInfo: {
        title: 'Account',
    },
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
      currentUser(): CurrentUser | null {
        return this.$store.state.account.currentUser;
      },
    },
});
</script>
<style lang="postcss" scoped>
.account-container {
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
    height: 32px;
    justify-content: flex-start;
}
.v-tabs--vertical > .v-tabs-bar .v-tab + .v-tab {
    margin-top: var(--spacing-2);
}
</style>
