<template>
    <v-container v-if="dataLoaded">
        <h2 class="t-subtitle mb-6">{{ $t('settings.profile.accountHeading') }}</h2>

        <validation-provider v-slot="{ errors }" name="first name" rules="required">
            <base-input v-model="userData.firstName" :label="$t('settings.profile.firstName')" :error-messages="errors" required />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="last name" rules="required">
            <base-input v-model="userData.lastName" :label="$t('settings.profile.lastName')" :error-messages="errors" required />
        </validation-provider>

        <two-step-address-selector
            ref="addressInput"
            :location-label="$t('settings.profile.location')"
            :location.sync="userData.location"
            :coordinates-label="false"
            :coordinates.sync="userData.coordinates"
        >
            <template v-if="userData.coordinates" #in-between>
                <p>
                    Lng ({{ userData.coordinates[0].toFixed(6) }})
                    Lat ({{ userData.coordinates[1].toFixed(6) }})
                </p>
            </template>
        </two-step-address-selector>

        <v-radio-group v-model="userData.preferredSystemOfMeasure">
            <base-fieldset>
                <template #legend>Units of measurement</template>
                <v-radio value="METRIC" label="Metric" />
                <v-radio value="IMPERIAL" label="US" />
            </base-fieldset>
        </v-radio-group>

        <h2 class="t-subtitle my-6">{{ $t('settings.profile.profileDetailsHeading') }}</h2>

        <div class="faux-label">{{ $t('settings.profile.profileImage') }}</div>

        <div class="profile-photo">
            <user-avatar v-if="currentUser" image-only :user="currentUser" size="extra-large" />
            <base-photo-input :input-label="$t('settings.profile.profileImage')" @loaded="updateProfilePhoto">
                <base-button tag="span" color="primary" fab rounded small :loading="loadingAvatar">
                    <span class="d-sr-only">({{ $t('settings.profile.setProfileImage') }})</span>
                    <v-icon>add</v-icon>
                </base-button>
            </base-photo-input>
        </div>
        <p>{{ $t('settings.profile.profileImageHint') }}</p>

        <base-text-area v-model="userData.description">
            <template #label>
                {{ $t('settings.profile.quickBio') }}
                <span class="label-tag">{{ $t('settings.profile.optional') }}</span>
            </template>
        </base-text-area>

        <action-footer class="text-center">
            <base-button color="primary" prepend-icon="check" :loading="loading" @click="saveUserData">{{ $t('settings.profile.save') }}</base-button>
        </action-footer>
    </v-container>
</template>

<script lang="ts">
import { CurrentUser, MBAddressObj, LngLat } from '@/types';
import Vue from '@/vueTyped';
import { reverseGeocode, parseAddressComponentsFromMBobj } from '@/util.mapbox';
import TwoStepAddressSelector from '@/components/location/TwoStepAddressSelector.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import ActionFooter from '@/components/ActionFooter.vue';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import { trackProfileUpdate } from '@/tracking';
import BaseFieldset from '@/components/BaseFieldset.vue';

export default Vue.extend({
    name: 'ProfileSettings',
    components: {
        ValidationProvider,
        TwoStepAddressSelector,
        UserAvatar,
        ActionFooter,
        BaseFieldset,
    },
    data() {
        return {
            newPhoto: null,
            dataLoaded: false,
            userData: {
                firstName: '',
                lastName: '',
                description: '',
                location: null as MBAddressObj | null,
                coordinates: null as LngLat | null,
                preferredSystemOfMeasure: '',
            },
            loadingAvatar: false,
            loading: false,
        };
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            async handler(val: CurrentUser) {
                if (val) {
                    this.userData.firstName = val.firstName;
                    this.userData.lastName = val.lastName;
                    this.userData.description = val.description;
                    this.userData.coordinates = null;
                    this.userData.location = null;
                    this.userData.preferredSystemOfMeasure = val.userSettings?.preferredSystemOfMeasure ?? '',
                    this.dataLoaded = true;

                    if (val.lng && val.lat) {
                        this.userData.coordinates = [val.lng, val.lat];
                        const coordinatesLookup = await reverseGeocode(this.userData.coordinates, { limit: 1 });
                        this.userData.location = coordinatesLookup.features[0];
                    }
                }
            },
        },
    },
    methods: {
        async updateProfilePhoto(photo: { webLink: string, file: any }) {
            if (this.currentUser) {
                this.loadingAvatar = true;
                await this.$store.dispatch('updateAvatar', {
                    file: photo.file,
                    userId: this.currentUser.id,
                });
                this.loadingAvatar = false;
            }
        },
        async saveUserData() {
            if (!this.currentUser) { return; }

            (this.$refs.addressInput as any).validate();

            const payload = {} as any;

            if (this.userData.firstName !== this.currentUser.firstName) {
                payload.firstName = this.userData.firstName;
            }

            if (this.userData.lastName !== this.currentUser.lastName) {
                payload.lastName = this.userData.lastName;
            }

            if (this.userData.description !== this.currentUser.description) {
                payload.description = this.userData.description;
            }

            if (this.userData.coordinates) {
                if (this.userData.coordinates[0] !== this.currentUser.lng || this.userData.coordinates[1] !== this.currentUser.lat) {
                    payload.lng = this.userData.coordinates[0];
                    payload.lat = this.userData.coordinates[1];

                    if (this.userData.location /* This should always be true in this case. */) {
                        payload.addressComponents = parseAddressComponentsFromMBobj(this.userData.location);
                    }
                }

            }

            this.loading = true;

            // Save settings first, since they're used to set defaults when updating details.
            if (this.userData.preferredSystemOfMeasure !== this.currentUser.userSettings?.preferredSystemOfMeasure) {
                await this.$store.dispatch('updateSettings', {
                    preferredSystemOfMeasure: this.userData.preferredSystemOfMeasure,
                });
            }

            const { data } = await this.$store.dispatch('updateUserDetails', payload);
            trackProfileUpdate(data.users[0]);

            this.loading = false;
            if (this.$route.query.redirect) {
                this.$router.push(String(this.$route.query.redirect));
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
.profile-photo {
    position: relative;
    display: inline-block;
    margin: 0 auto;
}
.profile-photo :deep(.photo-input) {
    position: absolute;
    bottom: -4px;
    right: -4px;
}
.faux-label {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;
    opacity: 0.6;
    text-transform: uppercase;
}
.label-tag {
    font-weight: normal;
    text-transform: none;
}
</style>
