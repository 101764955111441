<template>
    <v-container class="profile">
        <v-layout v-for="d in userDevices" :key="d.id" class="device mb-8">
            <v-flex shrink>
                <async-image v-if="d.images.length" :image="d.images[0]" :height="75" :width="75" :alt="`${d.type.label} location photo`" />
            </v-flex>
            <v-flex class="pl-3">
                <p class="mb-0"><strong>{{ d.type.label }}</strong></p>
                <p>{{ d.addressComponents.city }} {{ d.addressComponents.state }} {{ d.addressComponents.countryCode }}</p>
            </v-flex>
            <v-flex shrink>
                <base-button text color="primary">Remove</base-button>
            </v-flex>
        </v-layout>
        <h2>{{ $t('settings.sensors.wantToHost.headline') }}</h2>
        <i18n tag="p" path="settings.sensors.wantToHost.content">
            <a href="mailto:team@iseechange.org">team@iseechange.org</a>
        </i18n>
    </v-container>
</template>
<script lang="ts">
import { CurrentUser, User, Device } from '@/types';
import Vue from '@/vueTyped';
import AsyncImage from '@/components/AsyncImage.vue';

export default Vue.extend({
    name: 'ProfileSensors',
    components: {
        AsyncImage,
    },
    data() {
        return {
        };
    },
    computed: {
        currentUser(): CurrentUser | User | null {
            return this.$store.state.account.currentUser;
        },
        userDevices(): Device[] {
            return this.$store.state.account.userDevices;
        },
    },
    created() {
        this.$store.dispatch('fetchDevices');
    },

});
</script>
<style lang="postcss" scoped>
.device :deep(.image-wrapper) {
    width: 75px;
}
</style>
